.timer {
  height: 100%;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.top-group {
  padding: 10vh;
}

.mid-group {
  text-align: center;
  transition-duration: .5s;
}

.active {
  color: #1C1C1C;
}

.inactive {
  color: #8F8F8F;
}

.bottom-group {
  padding: 10vh;
}

.turn {
  font-size: 20vh;
}

.time {
  font-size: 4vh;
  font-weight: bold;
}

.button-group {
  display: flex;
}

button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: large;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #1C1C1C;
  border-radius: 8px;
  padding: 0.25em 0.75em;
  min-width: 10ch;
  min-height: 44px;
  text-align: center;
  line-height: 1.1;
  border: 2px solid #1C1C1C;
  transition-duration: .5s;
}

button.switch {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #1C1C1C;
}

button.reset {
  border: none;
  border-radius: 0;
  border-top: 2px solid #1C1C1C;
}

button:disabled {
  color: #8F8F8F;
  border-color: #8F8F8F;
}

